import React from 'react';
import { FaListUl } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { PlaylistPlayerWithSelector } from './PlaylistPlayer';
import styles from './SongDay.module.css';

import { data } from '../songcalendar/data';

export const SongDay: React.FC = () => {
  const { round, day } = useParams();
  const [roundValue, setRoundValue] = React.useState<number>();
  const [dayValue, setDayValue] = React.useState<number>();
  React.useEffect(() => {
    if (!round || !day) {
      return;
    }
    setRoundValue(parseInt(round));
    setDayValue(parseInt(day));
  }, [day, round]);
  if (!roundValue || !dayValue) {
    return null;
  }
  const songDay = data[roundValue - 1].days[dayValue - 1];
  return (
    <div className="min-h-screen">
      <div className="flex justify-between items-center">
        <Link to={`/song-calendar/${roundValue}`}
          className="p-4 bg-pink-500 rounded-full items-center hover:bg-pink-300 fixed shadow-md top-4 left-4">
          <FaListUl size="32px" className="text-white"/>
        </Link>
      </div>
      <div>
        <div className="mt-16 text-center font-bold italic text-pink-400 text-lg">{songDay.name}</div>
        <div className="mt-4 text-center text-5xl px-20 font-pacifico text-pink-500">{songDay.topic}</div>
        <div className="flex mt-20 mb-32 justify-center">
          <div className="flex flex-col items-center">
            <div className="text-center font-pacifico text-red-500 text-xl mb-2">Phiêu time :&gt;</div>
            <PlaylistPlayerWithSelector round={roundValue - 1} day={dayValue - 1}/>
          </div>
          <div className="w-1/4 ml-8">
            <div className="text-center font-pacifico text-yellow-500 text-xl mb-3">Chit chat xíu hihi</div>
            <div className="flex flex-col w-full items-center">
              {songDay.comments.length ? (
                songDay.comments.map((comment, index) => (
                  <div
                    className={styles.comment}
                    key={index}
                    style={{ width: 'fit-content' }}
                  >
                    {comment}
                  </div>
                ))
              ) : (
                <div
                  className={classNames('text-center break-words mb-3 py-2 px-3 rounded-lg shadow-md text-gray-700', 'bg-yellow-200')}
                >
                  💕
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
