import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { HomePage } from './page/home/HomePage';
import { SongCalendar } from './page/songcalendar/SongCalendar';
import { ParticlesLayout } from './ui/layout/particleslayout/ParticlesLayout';
import { SongDay } from './page/songday/SongDay';

export const AppRouter: React.FC = () => {
  return (
    <ParticlesLayout>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/song-calendar/:round" element={<SongCalendar/>}/>
        <Route path="/song-calendar/:round/day/:day" element={<SongDay/>}/>
      </Routes>
    </ParticlesLayout>
  );
};
