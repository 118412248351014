import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaHome } from 'react-icons/fa';

import styles from './SongCalendar.module.css';
import { data, Day } from './data';
import { Gradient, gradientData } from './gradients';
import { youtubeVideos } from './youtubeVideos';

import { PlaylistPlayer } from '../songday/PlaylistPlayer';

export const SongCalendar: React.FC = () => {
  const navigate = useNavigate();
  const { round } = useParams();
  const [roundValue, setRoundValue] = React.useState<number>();
  const [videoIds, setVideoIds] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!round) {
      return;
    }
    const roundValue = parseInt(round);
    if (roundValue !== 1 && roundValue !== 2 && roundValue !== 3) {
      navigate('/');
      return;
    }
    setRoundValue(roundValue - 1);
  }, [navigate, round]);

  React.useEffect(() => {
    if (roundValue === undefined) {
      return;
    }
    const month = data[roundValue];
    const newVideoIds = [];
    for (const day of month.days) {
      for (const videoLink of day.grape) {
        const videoId = videoLink.substr('https://youtu.be/'.length);
        if (youtubeVideos.get(videoId) !== undefined) {
          newVideoIds.push(videoId);
        }
      }
      for (const videoLink of day.watermelon) {
        const videoId = videoLink.substr('https://youtu.be/'.length);
        if (youtubeVideos.get(videoId) !== undefined) {
          newVideoIds.push(videoId);
        }
      }
    }
    setVideoIds(newVideoIds);
  }, [roundValue]);

  if (roundValue === undefined) {
    return null;
  }

  const month = data[roundValue];
  const gradients = gradientData.sort(() => 0.5 - Math.random()).slice(0, 30);
  const dayItems = Array.from({ length: 30 }, (x, i) => (
    <DayItem day={month.days[i]} gradient={gradients[i]} index={i} key={i} round={roundValue}/>
  ));

  const watermelons: string[] = [];
  const grapes: string[] = [];
  const both: string[] = [];
  for (const day of month.days) {
    for (const videoLink of day.grape) {
      const videoId = videoLink.substr('https://youtu.be/'.length);
      if (youtubeVideos.get(videoId) !== undefined) {
        grapes.push(videoId);
        both.push(videoId);
      }
    }
    for (const videoLink of day.watermelon) {
      const videoId = videoLink.substr('https://youtu.be/'.length);
      if (youtubeVideos.get(videoId) !== undefined) {
        watermelons.push(videoId);
        both.push(videoId);
      }
    }
  }

  const prevPath = roundValue === 0 ? '/song-calendar/3' : `/song-calendar/${roundValue}`;
  const nextPath = roundValue === 2 ? '/song-calendar/1' : `/song-calendar/${roundValue + 2}`;

  return (
    <div>
      <div className="flex justify-between items-center">
        <Link
          to="/"
          className="p-4 bg-pink-500 rounded-full items-center hover:bg-pink-300 shadow-md fixed top-4 left-4"
        >
          <FaHome size="32px" className="text-white"/>
        </Link>
      </div>
      <div className={styles.title}>Round {roundValue + 1}</div>
      <div className="relative" style={{ width: '800px' }}>
        <div className={styles.prevBtn}>
          <Link
            to={prevPath}
            className={styles.navBtn}
          >
            <FaChevronLeft size="32px"/>
          </Link>
        </div>
        <div className={styles.nextBtn}>
          <Link
            to={nextPath}
            className={styles.navBtn}
          >
            <FaChevronRight size="32px"/>
          </Link>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <button
          className="px-2 py-1 bg-purple-400 text-white rounded-md shadow-md text-md hover:bg-purple-300 mr-2"
          onClick={() => setVideoIds(grapes)}
        >
          Bài của Nho Nhỏ
        </button>
        <button
          className="px-2 py-1 bg-red-400 text-white rounded-md shadow-md text-md hover:bg-red-300 mr-2"
          onClick={() => setVideoIds(watermelons)}
        >
          Bài của Hấu Béo
        </button>
        <button
          className="px-2 py-1 bg-yellow-400 text-white rounded-md shadow-md text-md hover:bg-yellow-300"
          onClick={() => setVideoIds(both)}
        >
          Bài của 2 chúng mình :v
        </button>
      </div>
      <div className="flex flex-col items-center mt-4">
        <PlaylistPlayer videoIds={videoIds} width={600} height={500} playing={Math.random()} round={roundValue}/>
      </div>
      <div className="flex flex-col items-center justify-center mb-36 mt-8">
        {dayItems}
      </div>
    </div>
  );
};

type DayItemProps = {
  day: Day;
  gradient: Gradient;
  index: number;
  round: number;
};

const DayItem: React.FC<DayItemProps> = ({ day, gradient, index, round }) => {
  return (
    <Link
      to={`/song-calendar/${round + 1}/day/${index + 1}`}
      className={styles.dayItemLink}
      style={{ background: gradient.gradient }}
    >
      <div>
        {day.name}: {day.topic}
      </div>
    </Link>
  );
};
