import React from 'react';
import Particles from 'react-tsparticles';

import styles from './ParticlesLayout.module.css';

export const ParticlesLayout: React.FC = ({children}) => {
  return (
    <div>
      <Particles
        className={styles.container}
        canvasClassName={styles.canvas}
        options={{
          fpsLimit: 24,
          fullScreen: {
            enable: true,
            zIndex: 0,
          },
          particles: {
            number: {
              value: 100,
              density: {
                enable: false,
                value_area: 800,
              },
            },
            color: {
              value: '#FDE68A',
            },
            shape: {
              type: 'star',
              options: {
                star: {
                  sides: 5,
                },
              },
            },
            opacity: {
              value: 1,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 8,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 150,
              color: '#ffffff',
              opacity: 0.6,
              width: 1,
            },
            move: {
              enable: true,
              speed: 1,
              random: true,
              out_mode: 'bounce',
              bounce: true,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          retina_detect: true,
        }}
      />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};
