import React from 'react';
import { Link } from 'react-router-dom';

import styles from './HomePage.module.css';

export const HomePage: React.FC = () => {
  return (
    <div>
      <div className={styles.title}>Nho & Hấu</div>
      <div className={styles.subtitle}>
        Gửi tặng quả Nho xinh của Hấu nhân dịp chúng mình kỉ niệm 1 năm 6 tháng 💕<br/><br/>
        Nho còn nhớ hồi đầu mình chơi trò 30 days song challenge hong :v<br/>
        Nhờ vậy mà chúng mình mới có cơ hội để trò chuyện nhiều hơn và hiểu về nhau hơn<br/><br/>
        Hi thế nên giờ Hấu làm trang này để mình có thể nghe được hết những bài hát<br/>
        thật dễ dàng nhó hihi<br/><br/>
        Chúc công chúa của anh nghe nhạc thật vui và nghĩ về Hấu mỗi khi nghe nha 🥰<br/>
        Hấu béo yêu Nho nhỏ nhiều lắm ❤️ ❤️ ❤️
      </div>
      <div className="mt-10 flex justify-center">
        <Link className={styles.songCalendarLink} to="song-calendar/1">30 days song challenge :&gt;</Link>
      </div>
    </div>
  );
};
