export type Day = {
  name: string;
  topic: string;
  watermelon: string[];
  grape: string[];
  comments: string[];
};

export type Month = {
  name: string;
  days: Day[];
};

export const data: Month[] = [
  {
    name: 'Month 1',
    days: [
      {
        name: 'Day 1',
        topic: 'The songs you like with a color in the title',
        watermelon: [
          'https://youtu.be/wXsBaFCfXb0',
        ],
        grape: [
          'https://youtu.be/NRKjr8H_jxg',
        ],
        comments: [
          '1ST day <6.3.20>',
        ],
      },
      {
        name: 'Day 2',
        topic: 'The songs you like with a number in the title',
        watermelon: [
          'https://youtu.be/42Gtm4-Ax2U',
        ],
        grape: [
          'https://youtu.be/e4IUWLMPuC8',
        ],
        comments: [],
      },
      {
        name: 'Day 3',
        topic: 'The songs that reminds you of summertime',
        watermelon: [
          'https://youtu.be/ZsYwEV_ge4Y',
        ],
        grape: [
          'https://youtu.be/H2HQWHKDREI',
        ],
        comments: [],
      },
      {
        name: 'Day 4',
        topic: 'The songs that reminds you of someone you\'d rather forger',
        watermelon: [
          'https://youtu.be/NJp5Fz3mTu0',
        ],
        grape: [
          'https://youtu.be/Wp0Lou3y5-o',
          'https://youtu.be/56fTl7dtFkM',
        ],
        comments: [
          'T n e c v t t n t n',
          'Hấu dịch gần đúng hết luôn :>',
        ],
      },
      {
        name: 'Day 5',
        topic: 'The songs that needs to be played loud',
        watermelon: [
          'https://youtu.be/mWRsgZuwf_8',
        ],
        grape: [
          'https://youtu.be/UtF6Jej8yb4',
        ],
        comments: [
          '10/03/2020',
        ],
      },
      {
        name: 'Day 6',
        topic: 'The songs that makes you want to dance',
        watermelon: [
          'https://youtu.be/yTCDVfMz15M',
        ],
        grape: [
          'https://youtu.be/ritnXLZHgmc',
        ],
        comments: [],
      },
      {
        name: 'Day 7',
        topic: 'The songs to drive to',
        watermelon: [
          'https://youtu.be/g1j1qwQQ8-Q',
        ],
        grape: [
          'https://youtu.be/RpDHdhTvgMs',
        ],
        comments: [],
      },
      {
        name: 'Day 8',
        topic: 'The songs about drugs or alcohol',
        watermelon: [
          'https://youtu.be/AywD3LNR6cM',
          'https://youtu.be/8jU___yGRCI',
          'https://youtu.be/OpQFFLBMEPI',
          'https://youtu.be/M-qh92TNETo',
          'https://youtu.be/tv0ttp0bS8A',
        ],
        grape: [
          'https://youtu.be/aGUQsb31TEw',
        ],
        comments: [
          'Công chúa👸🏻',
          'Hoàng tử🤴🏻',
        ],
      },
      {
        name: 'Day 9',
        topic: 'The songs that make you happy',
        watermelon: [
          'https://youtu.be/rAaecaFf4mk',
          'https://youtu.be/PwC1MxbVr8U',
        ],
        grape: [
          'https://youtu.be/mk48xRzuNvA',
        ],
        comments: [],
      },
      {
        name: 'Day 10',
        topic: 'The songs that make you sad',
        watermelon: [
          'https://youtu.be/Yb2arWjBhp0',
          'https://youtu.be/FVX69XqZPhM',
        ],
        grape: [
          'https://youtu.be/VARrZhhB9Vc',
          'https://youtu.be/v9cfJfQm9RU',
        ],
        comments: [
          '❤️💕 15.3',
        ],
      },
      {
        name: 'Day 11',
        topic: 'The songs you never get tired of',
        watermelon: [
          'https://youtu.be/8nIao--t0Ls',
          'https://youtu.be/ojG9C6L61oA',
          'https://youtu.be/SreECl_7iOU',
          'https://youtu.be/dm-a64Ft4og',
          'https://youtu.be/LTg6gxwPPEM',
          'https://youtu.be/rgXIpzHvD-g',
          'https://youtu.be/_jtUll4ZrOw',
        ],
        grape: [
          'https://youtu.be/qMWXVc3WAYs',
          'https://youtu.be/wN83dn07TUw',
          'https://youtu.be/h4zcjaAmNl8',
          'https://youtu.be/6ynkwAe7oyU',
          'https://youtu.be/NUGtT-aPKDo',
        ],
        comments: [],
      },
      {
        name: 'Day 12',
        topic: 'The songs from your preteen years',
        watermelon: [
          'https://youtu.be/9bxc9hbwkkw',
          'https://youtu.be/j5XAS-0ZL1g',
          'https://youtu.be/ASRkn9OEzjA',
          'https://youtu.be/M4aqhUgqEcQ',
        ],
        grape: [
          'https://youtu.be/pPqzkDW_emY',
          'https://youtu.be/e7h1lH_bgL0',
          'https://youtu.be/nz5zN2UjAYw',
          'https://youtu.be/SZIDwCqD86w',
          'https://youtu.be/1w17kpYT1wI',
          'https://youtu.be/j1svZDnF-N4',
        ],
        comments: [],
      },
      {
        name: 'Day 13',
        topic: 'The songs you like from the 70s',
        watermelon: [
          'https://youtu.be/2X_2IdybTV0',
          'https://youtu.be/WrQyPHzzfCA',
        ],
        grape: [
          'https://youtu.be/IobNcpiwpSc',
        ],
        comments: [
          '❤️',
          'thêm một đêm mất ngủ',
        ],
      },
      {
        name: 'Day 14',
        topic: 'The songs you\'d love to be played at your wedding',
        watermelon: [
          'https://youtu.be/04HK7Pl5NNs',
          'https://youtu.be/bu7nU9Mhpyo',
          'https://youtu.be/GtLWMYVST-I',
          'https://youtu.be/E7sxc2aYTmo',
          'https://youtu.be/LTg6gxwPPEM',
          'https://youtu.be/2Vv-BfVoq4g',
        ],
        grape: [
          'https://youtu.be/O6mizTWwmTQ',
          'https://youtu.be/kurwg1zCixs',
          'https://youtu.be/ldneQsewJEc',
          'https://youtu.be/fJX0s5tjBFw',
          'https://youtu.be/FC7p9v-xtm4',
          'https://youtu.be/D4Fi1YBbzDY',
        ],
        comments: [],
      },
      {
        name: 'Day 15',
        topic: 'The songs you like that\'s cover by another artist',
        watermelon: [
          'https://youtu.be/DwTinTO0o9I',
          'https://youtu.be/YX8Bk453iK0',
          'https://youtu.be/hG2ekffXMhs',
          'https://youtu.be/KB7wev8mIbQ',
          'https://youtu.be/ewQ6regg2vY',
          'https://youtu.be/r_W3Sf-n3N4',
        ],
        grape: [
          'https://youtu.be/ab1WL6y1S28',
        ],
        comments: [
          '<20.03.20> Hấu bay từ Úc về SG rồi lại bay từ SG về nhà.. sắp được gặp nhau ruj',
        ],
      },
      {
        name: 'Day 16',
        topic: 'The songs that\'s a classic favorite',
        watermelon: [
          'https://youtu.be/NlprozGcs80',
          'https://youtu.be/7maJOI3QMu0',
          'https://youtu.be/-sWnEWpS_fA',
          'https://youtu.be/9fAZIQ-vpdw',
          'https://youtu.be/mJ_fkw5j-t0',
          'https://youtu.be/QgaTQ5-XfMM',
          'https://youtu.be/yET4p-r2TI8',
          'https://youtu.be/b1925VG7Dqc',
          'https://youtu.be/dgZ4IAkIs18',
          'https://youtu.be/YPf56De38Qc',
          'https://youtu.be/sRjffNx3ce4',
          'https://youtu.be/pnbeGTeZCpg',
          'https://youtu.be/rF5zz3ERfU0',
          'https://youtu.be/l9vOgrQCfnI',
          'https://youtu.be/gLo5cgBYDpE',
          'https://youtu.be/t6wBKcYIFGE',
        ],
        grape: [
          'https://youtu.be/-sWnEWpS_fA',
          'https://youtu.be/yr3dNir6BfQ',
          'https://youtu.be/R9Y3-PxiD7s',
          'https://youtu.be/la0-5QFLr14',
          'https://youtu.be/GIDoQsQyS0s',
          'https://youtu.be/YSqtv-3ijk8',
          'https://youtu.be/BKH_rw5y23o',
          'https://youtu.be/O4uD6o9XxLs',
          'https://youtu.be/1Rvb7KavvYo',
          'https://youtu.be/ANDGurOLuGE',
        ],
        comments: [
          'Hấu gửi toàn bài bay thôi ~~',
          'Chung múi giờ cái bắt sóng tốt hẳn :v',
          'đúng thật vì yêu là điều đặc biệt nhất của con người mà',
        ],
      },
      {
        name: 'Day 17',
        topic: 'The songs you\'d sing a duet with someone on karaoke',
        watermelon: [
          'https://youtu.be/Mtiz26WdeKA',
        ],
        grape: [
          'https://youtu.be/yO28Z5_Eyls',
          'https://youtu.be/39Z9TSh8crA',
        ],
        comments: [
          'Khoe tóc ngố xong Hấu khen xưn :3',
          'cái gì mà đứng hình, điuuuu',
        ],
      },
      {
        name: 'Day 18',
        topic: 'The songs from the year you were born',
        watermelon: [
          'https://youtu.be/7NrQei36fJk',
          'https://youtu.be/4fndeDfaWCg',
        ],
        grape: [
          'https://youtu.be/ulOb9gIGGd0',
          'https://youtu.be/yKNxeF4KMsY',
        ],
        comments: [],
      },
      {
        name: 'Day 19',
        topic: 'The songs that makes you think about life',
        watermelon: [
          'https://youtu.be/V1bFr2SWP1I',
          'https://youtu.be/uh4dTLJ9q9o',
          'https://youtu.be/qMuKw1NYpjs',
          'https://youtu.be/qt_OkgSOrkU',
          'https://youtu.be/orL-w2QBiN8',
          'https://youtu.be/1ItrO33Hg48',
          'https://youtu.be/3Ba_WoSZXvw',
        ],
        grape: [
          'https://youtu.be/nQY4dIxY1H4',
          'https://youtu.be/6jZVsr7q-tE',
          'https://youtu.be/Of-UkRiRWeo',
          'https://youtu.be/qBB_QOZNEdc',
          'https://youtu.be/-Vo_t4pgDqA',
          'https://youtu.be/AtNBhPxVwh0',
        ],
        comments: [],
      },
      {
        name: 'Day 20',
        topic: 'The songs that has many meanings to you',
        watermelon: [
          'https://youtu.be/hmgHvuTdNXE',
          'https://youtu.be/Cgy5lFLu9aA',
          'https://youtu.be/mHeK0Cwr9sg',
          'https://youtu.be/FM7MFYoylVs',
        ],
        grape: [
          'https://youtu.be/TI0DGvqKZTI',
          'https://youtu.be/elsh3J5lJ6g',
        ],
        comments: [
          'Không biết nấu thì Hấu biết',
        ],
      },
      {
        name: 'Day 21',
        topic: 'The songs you like with a person\'s name in the title',
        watermelon: [
          'https://youtu.be/9NQcxue_yIk',
          'https://youtu.be/-FDKAgjb7T4',
          'https://youtu.be/jWX8H0_SWhY',
          'https://youtu.be/-nnWBhKZeg0',
          'https://youtu.be/K5H-GvnNz2Y',
        ],
        grape: [
          'https://youtu.be/kbiaBEYCAqM',
          'https://youtu.be/BBK4kHrhmP0',
        ],
        comments: [
          'Chả hiểu sao đi dỗi Hấu...',
          'huhu biết bao giờ mới lành đây',
        ],
      },
      {
        name: 'Day 22',
        topic: 'The songs that moves you forward',
        watermelon: [
          'https://youtu.be/w5tWYmIOWGk',
          'https://youtu.be/an4ySOlsUMY',
        ],
        grape: [
          'https://youtu.be/cPAbx5kgCJo',
          'https://youtu.be/xo1VInw-SKc',
          'https://youtu.be/UjnDpcgJXvA',
        ],
        comments: [
          '14:52 em nhớ anh qạ',
        ],
      },
      {
        name: 'Day 23',
        topic: 'The songs you think everybody should listen to',
        watermelon: [
          'https://youtu.be/Sv6dMFF_yts',
          'https://youtu.be/PIh2xe4jnpk',
          'https://youtu.be/fLexgOxsZu0',
          'https://youtu.be/fWNaR-rxAic',
          'https://youtu.be/LjhCEhWiKXk',
          'https://youtu.be/ktvTqknDobU',
          'https://youtu.be/sENM2wA_FTg',
          'https://youtu.be/ktvTqknDobU',
          'https://youtu.be/sENM2wA_FTg',
          'https://youtu.be/LHCob76kigA',
        ],
        grape: [
          'https://youtu.be/KRaWnd3LJfs',
          'https://youtu.be/fKopy74weus',
          'https://youtu.be/5anLPw0Efmo',
          'https://youtu.be/dhYOPzcsbGM',
          'https://youtu.be/cmSbXsFE3l8',
          'https://youtu.be/rg_zwK_sSEY',
          'https://youtu.be/8xg3vE8Ie_E',
          'https://youtu.be/ea2kr3yTpFw',
          'https://youtu.be/AJtDXIazrMo',
        ],
        comments: [],
      },
      {
        name: 'Day 24',
        topic: 'The songs by a band you wish were still together',
        watermelon: [
          'https://youtu.be/5H2zn1zPWPQ',
          'https://youtu.be/TXpGWhdwXuo',
          'https://youtu.be/QJO3ROT-A4E',
          'https://youtu.be/QnEWs8D9zu8',
        ],
        grape: [
          'https://youtu.be/NU9JoFKlaZ0',
          'https://youtu.be/vLbfv-AAyvQ',
          'https://youtu.be/JDgtTGcEJtE',
          'https://youtu.be/0Gl2QnHNpkA',
          'https://youtu.be/2ggzxInyzVE',
        ],
        comments: [],
      },
      {
        name: 'Day 25',
        topic: 'The songs you like by an artist no longer living',
        watermelon: [
          'https://youtu.be/3JWTaaS7LdU',
          'https://youtu.be/BWf-eARnf6U',
        ],
        grape: [
          'https://youtu.be/aLEhh_XpJ-0',
          'https://youtu.be/sgcsFCapMxA',
        ],
        comments: [],
      },
      {
        name: 'Day 26',
        topic: 'The songs that makes you want to fall in love',
        watermelon: [
          'https://youtu.be/Is2Gs4EEw7k',
          'https://youtu.be/zqKoXPHhmsM',
          'https://youtu.be/DOZlilR7Pgs',
          'https://youtu.be/JkqLdK85vj0',
          'https://youtu.be/2Vv-BfVoq4g',
          'https://youtu.be/jeqdYqsrsA0',
          'https://youtu.be/NJR8Inf77Ac',
          'https://youtu.be/3HbKnQxd0_E',
          'https://youtu.be/OHbwkZgkBIU',
          'https://youtu.be/VL62lhiLTd4',
          'https://youtu.be/ShlW5plD_40',
          'https://youtu.be/CyI5nrZf_JQ',
          'https://youtu.be/X8mhF6HgzVA',
          'https://youtu.be/t0WFOnwp3MM',
          'https://youtu.be/iE52-XXnQqs',
          'https://youtu.be/I3RICWXA_3U',
          'https://youtu.be/7c39lfT6Sr0',
          'https://youtu.be/_YzngEllRgM',
          'https://youtu.be/CVx2jS_6nxo',
          'https://youtu.be/9m2z8bnTgJA',
          'https://youtu.be/izFnBP3lC3Q',
          'https://youtu.be/1P4DaXgzVnE',
          'https://youtu.be/Hey0G8CPvbA',
          'https://youtu.be/hQklJKoEmjs',
          'https://youtu.be/DL5EAV-WZx4',
          'https://youtu.be/oQLrdHUKMS0',
          'https://youtu.be/2B5czYsvlnk',
          'https://youtu.be/igNVdlXhKcI',
        ],
        grape: [
          'https://youtu.be/GKn3GGCbh_0',
          'https://youtu.be/BxuY9FET9Y4',
          'https://youtu.be/Tqc3vTqDbEY',
          'https://youtu.be/i0vVqORZrVc',
          'https://youtu.be/VuNIsY6JdUw',
          'https://youtu.be/-Vo_t4pgDqA',
          'https://youtu.be/7vXw24k7SAI',
        ],
        comments: [],
      },
      {
        name: 'Day 27',
        topic: 'The songs that breaks your heart',
        watermelon: [
          'https://youtu.be/-FDKAgjb7T4',
          'https://youtu.be/cHbNaFNoHCY',
          'https://youtu.be/RBumgq5yVrA',
          'https://youtu.be/UwuAPyOImoI',
          'https://youtu.be/VlxTWhoPHhY',
          'https://youtu.be/XvTrhrqWVVk',
          'https://youtu.be/h_3izuqhJCk',
          'https://youtu.be/ekzHIouo8Q4',
          'https://youtu.be/XTVWGjWJAdI',
          'https://youtu.be/F6hhU9JGxxM',
          'https://youtu.be/ziFKrFf0Wwg',
          'https://youtu.be/OsCLXDFvyDs',
          'https://youtu.be/oKPcChunFH4',
          'https://youtu.be/_5_IpEvuIw4',
          'https://youtu.be/4uOHQ7mO-Kk',
          'https://youtu.be/D7pei7AX58k',
          'https://youtu.be/i67ZXLPeg9s',
          'https://youtu.be/P-uhgIzHYYo',
          'https://youtu.be/2W8r1WBSRNI',
          'https://youtu.be/WAT-Gy6QsTY',
          'https://youtu.be/jcRlGlu199o',
          'https://youtu.be/HP23uwLtkPQ',
          'https://youtu.be/ZAYZmIfHEiU',
          'https://youtu.be/kBqqlW6-99M',
          'https://youtu.be/vq2wZyi4vBE',
          'https://youtu.be/Dnj5Tcpev0Q',
        ],
        grape: [
          'https://youtu.be/uBIVFkxV308',
          'https://youtu.be/VARrZhhB9Vc',
          'https://youtu.be/BKH_rw5y23o',
          'https://youtu.be/UCXao7aTDQM',
          'https://youtu.be/yKS_y47JIz4',
          'https://youtu.be/BmErRm-vApI',
          'https://youtu.be/eqK-yQNdvtA',
          'https://youtu.be/W-MihXf7Y2c',
          'https://youtu.be/mH8MphDxyqY',
          'https://youtu.be/f5iVy-ZvdMk',
          'https://youtu.be/j6Tlcx8Mwo8',
          'https://youtu.be/hmz9FazAoGA',
          'https://youtu.be/EOGT548ZToY',
          'https://youtu.be/ctrdbTqVLy8',
          'https://youtu.be/MlM-lGHJ5hU',
        ],
        comments: [
          '<1',
          '<2',
          '<3',
          '<4? no <3 <3 <3',
        ],
      },
      {
        name: 'Day 28',
        topic: 'The songs by an artist whose voice you love',
        watermelon: [
          'https://youtu.be/Krxo4rRPJl0',
          'https://youtu.be/5kWI2_LXCh0',
        ],
        grape: [
          'https://youtu.be/6GUm5g8SG4o',
          'https://youtu.be/LJtuVMAPQLw',
          'https://youtu.be/IsXB5eRMRno',
          'https://youtu.be/Qp4n5F05GYM',
          'https://youtu.be/3-NTv0CdFCk',
        ],
        comments: [],
      },
      {
        name: 'Day 29',
        topic: 'The songs you remember from your childhood',
        watermelon: [
          'https://youtu.be/SIWcOdBtLRw',
          'https://youtu.be/gt3E_cmrnb0',
          'https://youtu.be/yizfXeK2usU',
          'https://youtu.be/gH476CxJxfg',
          'https://youtu.be/bCDIt50hRDs',
          'https://youtu.be/1w17kpYT1wI',
          'https://youtu.be/A_HekkBbd1M',
          'https://youtu.be/8YzabSdk7ZA',
          'https://youtu.be/-sdYvmpy2cg',
        ],
        grape: [
          'https://youtu.be/r00ikilDxW4',
          'https://youtu.be/EkHTsc9PU2A',
          'https://youtu.be/4KCeZSg6MTg',
          'https://youtu.be/tt2k8PGm-TI',
          'https://youtu.be/4vvBAONkYwI',
        ],
        comments: [
          '<3',
        ],
      },
      {
        name: 'Day 30',
        topic: 'The songs that reminds you of yourself',
        watermelon: [
          'https://youtu.be/2BOX4W00UrE',
          'https://youtu.be/tBSthP5LTZU',
          'https://youtu.be/r1Fx0tqK5Z4',
          'https://youtu.be/KgEQNlR4A6o',
        ],
        grape: [
          'https://youtu.be/CjxugyZCfuw',
          'https://youtu.be/r5yaoMjaAmE',
          'https://youtu.be/vEPAvEqYtOI',
          'https://youtu.be/Xn676-fLq7I',
          'https://youtu.be/mw5VIEIvuMI',
        ],
        comments: [
          '"The end of the beginning"',
        ],
      },
    ],
  },
  {
    name: 'Month 2',
    days: [
      {
        name: 'Day 1',
        topic: 'A song you discovered this month',
        watermelon: [
          'https://youtu.be/nysQ-d3seR8',
          'https://youtu.be/VQ03WpqlpCI',
        ],
        grape: [
          'https://youtu.be/GomHDyzaFoE',
          'https://youtu.be/zKxZ-DttK-4',
        ],
        comments: [
          'Hấu ơi Hấu à',
          'Hấu đây nè',
          'ớ  hao ô của anh lại là màu hồng',
          'Nick của anh màu hồng ó =)) Tại Hấu màu hồng =))',
        ],
      },
      {
        name: 'Day 2',
        topic: 'A song that always makes you smile',
        watermelon: [
          'https://youtu.be/qQHvXJIGPX4',
          'https://youtu.be/sLK4fS-FOHw',
          'https://youtu.be/pr09YsotYp8',
          'https://youtu.be/NLE8bv1Ymak',
          'https://youtu.be/I3RICWXA_3U',
          'https://youtu.be/FBmqNKQ9ACs',
          'https://youtu.be/AtXUt6KUTRo',
          'https://youtu.be/fnS0IH9nIBU',
          'https://youtu.be/4ISF8qPupQE',
        ],
        grape: [
          'https://youtu.be/fjjrO1ppGxU',
          'https://youtu.be/7-x3uD5z1bQ',
        ],
        comments: [
          'Dám chắc là lúc gõ chữ simle cười tủm tỉm đây mà =))',
          'Không biết anh có hay nghe nhạc không lời cho lúc cần tập trung suy nghĩ không, em hay nghe kiểu này nè',
          'Nhớ anh nhiềuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu',
          'Dưa Hấu ngọt ngào như đường ấy',
          'Ỏ yêu Nho 💕 Kiếm đâu ra bài hay zậy =)))',
          'Anh cũng kiếm được bài 🍇 nè =))',
          'Nho ngọt hơnnnnn',
          'Anh có nghe nha =)))',
          'Ui cái list nhạc đầu anh cũng có nghe qua rùi =))',
        ],
      },
      {
        name: 'Day 3',
        topic: 'A song that makes you cry T.T',
        watermelon: [
          'https://youtu.be/JeT796p_5cg',
          'https://youtu.be/zws-wD2Sr6A',
          'https://youtu.be/zVzbknPjmoQ',
        ],
        grape: [
          'https://youtu.be/PJKjYmDsLlw',
          'https://youtu.be/5n4V3lGEyG4',
          'https://youtu.be/F4nuUUJ1XlM',
        ],
        comments: [
          'Phùng Khánh Linh nè =))',
          'Ưm ngày xưa em thích nghe vì nó buồn, những cũng suy ngẫm nhiều ghê ấy',
          'ps: sẽ luôn happy ending mà ~~~',
          'Có Hấu ở đây rùi nè',
          'Cảm ơn anh <3 hí hí',
          'Nếu thấy mệt mỏi lại tâm sự với anh nữa nhé',
          'Đồng ý :3',
        ],
      },
      {
        name: 'Day 4',
        topic: 'A song you know all the words to',
        watermelon: [
          'https://youtu.be/8N-qO3sPMjc',
          'https://youtu.be/UVmWLJVMBa8',
          'https://youtu.be/nlXKe-YnX0o',
        ],
        grape: [
          'https://youtu.be/64VZUNTmGQM',
          'https://youtu.be/6KJrNWC0tfw',
          'https://youtu.be/NWdrO4BoCu8',
          'https://youtu.be/fBuNYi0Bdjw',
        ],
        comments: [
          'Hôm nay nhỡ làm Nho buồn 😭 Hấu đúng là đáng trách quá mà :(( Tặng Nho bài này nhé 🌹💓',
          '<3 hết dỗi rùi',
          'à bài của Park Bom lẽ ra cho vào ngày hôm qua thì hợp hơn :3',
          'màu hồng rực lun xinh quá huhuh',
          'đẹp hong hihi',
          'Ơ bài a gửi nghe cứ bùn bùn ấy',
          'Buồn buồn nhưng được giọng anh JSOL hay đó =))',
          'Bài hát này em biết từ lúc xem phim She was pretty, bộ phim mà có một đôi thân nhau từ bé, lớn lên bị mất liên lạc mãi mới nhận ra nhau :D có a Park Seo Joon đó',
          'Ấm áp quá 🤗',
        ],
      },
      {
        name: 'Day 5',
        topic: 'A song that proves you have a good taste',
        watermelon: [
          'https://youtu.be/8wgnyESh8sA',
          'https://youtu.be/8Qr9WW2bLAQ',
          'https://youtu.be/n4JRQ5clT28',
          'https://youtu.be/0DZcbLKhE8w',
          'https://youtu.be/58zztncG4HE',
          'https://youtu.be/pKEOgP5ARXU',
        ],
        grape: [
          'https://youtu.be/nrpjNgZCdlM',
          'https://youtu.be/c48O_AG01YI',
          'https://youtu.be/0-EMU7MC1RQ',
        ],
        comments: [
          'Woa nhạc HAT đúng là đặc biệt thật',
          '💙 HD nhạc cũng đặc bịt lun',
          'Bài này anh biết nè đoạn chị ấy hát nghe thích thật ý',
          'Nép vào anh nhé ~',
          '<3',
          'Hay quạ cơ íii',
          '💙',
          'ui bài này của a vừa hôm qua hôm kia em thấy gợi ý trên yt :o',
        ],
      },
      {
        name: 'Day 6',
        topic: 'A song title that is all in lowercase',
        watermelon: [
          'https://youtu.be/OVaTUsUrcYU',
          'https://youtu.be/uqvfbIuJd6Y',
          'https://youtu.be/TpeWxjtySKU',
        ],
        grape: [
          'https://youtu.be/Fvj6PE3gN4o',
          'https://youtu.be/V1Pl8CzNzCw',
          'https://youtu.be/EEhZAHZQyf4',
          'https://youtu.be/gEqlF5N8UMs',
        ],
        comments: [
          'Ôi mãi mới kiếm được 1 bài =))',
          'em lại hong mở dc cái này',
          'giờ được chưa nè',
          'rùi hihi hay thế ạ',
          'bài i love you cũng hay tóa',
          'OPPA',
          'yeodongsaeng',
          'là gì thế anh :v em có giỏi tiếng Hàn vậy đâu..',
          'thấy siêu hong là em gái đó =))',
          'woah =))',
          'ui em thích bài này.. trong annabelle :v',
          'hình như bài này trước em gửi rồi sao ý nghe quen quen =))',
          'hình như.... chưa mà',
          'vậy chắc hôm trước youtube recommend rùi',
        ],
      },
      {
        name: 'Day 7',
        topic: 'A song title that is all in uppercase',
        watermelon: [
          'https://youtu.be/8dVjSvLzD1I',
          'https://youtu.be/Z3INNjAEqHk',
          'https://youtu.be/ePpPVE-GGJw',
        ],
        grape: [
          'https://youtu.be/ypit0NcNV-8',
          'https://youtu.be/2GRP1rkE4O0',
          'https://youtu.be/qIt6KCwlFPw',
          'https://youtu.be/gyXy0m-4bvE',
          'https://youtu.be/GlYtC1mMHsg',
        ],
        comments: [
          'Park Bom nhiều bài hay quá <3',
          'Suýt cry rồi đó may nghe bài này =))',
          'í hí hi em cho nghe đúng thời điểm thế',
          'ơ mà đúng là nhạc Hàn quá nhiều bài uppercase luôn nhỉ. em bít bài này của Apink nè',
          'Ơ BIGBANG hả =))',
          'ưm hong lẽ SMALLBANG"',
          'Ôi TAEYANG bài nào cũng hay cả :\'(',
          'THẬT Ý, may em ko copy cả danh sách :3',
          'bài này hồi xưa Nho toàn nghe trên iTV nè',
          'bài năm trước mà MV đỉnh thật đó toàn anh rất là ngầu nữa =))',
          'không ngầu bằng anh Hấu =))))',
          'bài Nhật nữa nè',
          '💙💙💙',
        ],
      },
      {
        name: 'Day 8',
        topic: 'An underrated song',
        watermelon: [
          'https://youtu.be/X9RpZhoB4z4',
          'https://youtu.be/tO2CP6e6ftk',
          'https://youtu.be/HWgxbptCcuA',
          'https://youtu.be/jLDp-mDJeCA',
          'https://youtu.be/EhAX68uF6NU',
        ],
        grape: [
          'https://youtu.be/YOKoVSJWN3s',
          'https://youtu.be/EnYh4RDqBuw',
          'https://youtu.be/YOqAIDKrK9k',
          'https://youtu.be/9xkF6GKP_AM',
          'https://youtu.be/_lct31L_wLM',
        ],
        comments: [
          'Ngoài Apink anh còn thích cả GFRIEND nữa =))',
          'hí hí ❤️ toàn chị đã xinh gái còn hát hay',
          'bài này của CHEN e thích lắm, giờ mới để ý là cover của IU đóa',
          '🥰',
          'Người ấy phiên bản ngọng nè hihi',
          'Ỏ EXO hát hay quá =)))',
          '❤️tym ai to hơn💙',
          'á cái kênh yt này nhiều bài hay với nhìn đẹp nữaaa',
          'Two Worlds e có xem nek :o',
          'Tên kênh cũng hay luôn nữa =))',
        ],
      },
      {
        name: 'Day 9',
        topic: 'A song title that has three words',
        watermelon: [
          'https://youtu.be/BzYnNdJhZQw',
          'https://youtu.be/-qbqvV6Fqz4',
          'https://youtu.be/JA0j2-SAsBg',
        ],
        grape: [
          'https://youtu.be/Cq2-BgfWUVA',
          'https://youtu.be/sb3axG_4Zck',
          'https://youtu.be/UihNEvpyVzA',
        ],
        comments: [
          'Yêu tóa',
          '❤️ hong yêu"',
          '3 từ lun',
          'Bình thường người ta 1 2 3 xong tới 5 mới đánh rơi nhịp mà Nho 1 đã đánh rơi nhịp luôn hả =))',
          'Bài này k buồn nhưng nghe tự nhiên cũng buồn ý =))',
          '=))) ""tự nhiên buồn""',
          'Vừa hay vừa buồn :((',
          'ơ bài nài 4 từ kìaaa',
          'Tên bài là meaning of you đó cái link này thêm the vào =))',
        ],
      },
      {
        name: 'Day 10',
        topic: 'A song from your childhood',
        watermelon: [
          'https://youtu.be/qfTQEX6jshg',
          'https://youtu.be/3-I-lfjNg2Q',
        ],
        grape: [
          'https://youtu.be/2oo_xHMXnlM',
          'https://youtu.be/K7MyyiPTsXM',
          'https://youtu.be/5fdnPiC6TPU',
        ],
        comments: [
          'Noi voi em day la dieu toi te di. Cta chang the co co hoi nao nua sao?',
        ],
      },
      {
        name: 'Day 11',
        topic: 'A song that reminds you of summer',
        watermelon: [
          'https://youtu.be/nIl_gX9W5qQ',
          'https://youtu.be/g65oWFMSoK0',
        ],
        grape: [
          'https://youtu.be/Ahha3Cqe_fk',
          'https://youtu.be/zhsD4PJ-DBI',
          'https://youtu.be/DesXduvd67E',
        ],
        comments: [
          'Nho ơi Nho ở đâu rùi',
          'em ở đây nè ❤️',
        ],
      },
      {
        name: 'Day 12',
        topic: 'A song you feel embarrassed listening to',
        watermelon: [
          'https://youtu.be/nfWlot6h_JM',
          'https://youtu.be/zVO5xTAbxm8',
          'https://youtu.be/Zh9lynUFikc',
        ],
        grape: [
          'https://youtu.be/mPRy1B4t5YA',
          'https://youtu.be/fz6SHUbvgwo',
        ],
        comments: [
          'Cho anh gần em thêm chút nữa',
          'Để được ôm em trong vòng tay',
          'Để cùng nhau nhìn ngắm hoàng hôn buông và bầu trời sao lấp lánh',
          'Để rồi nhận ra rằng mình không còn lẻ loi',
          'Nói là pai làm đấy :3',
          'Tự nhiên nghĩ trong quãng thời gian dường như vô tận của vũ trụ, anh lại ở đây, gặp được em, và yêu em, điều đó thật đặc biệt nhỉ',
          'hum nay cảm xúc dâng trào dzay.',
          'Hấu làm em cảm thấy thật nhẹ nhàng và ấm áp',
          '💚',
        ],
      },
      {
        name: 'Day 13',
        topic: 'The first song that plays on shuffle',
        watermelon: [
          'https://youtu.be/SxqgmfykBfE',
        ],
        grape: [
          'https://youtu.be/SxqgmfykBfE',
        ],
        comments: [],
      },
      {
        name: 'Day 14',
        topic: 'A song that someone showed you',
        watermelon: [
          'https://youtu.be/i724lraI93s',
        ],
        grape: [
          'https://youtu.be/UuV2BmJ1p_I',
        ],
        comments: [
          'Bài này Hấu biết lúc mới vào trường c3 các anh chị chỉ cho =))',
          'Hay quá nhỉ, mới vào mà đã cho nghe gì mà yêu vs chả đương :)))',
          'bài của e thì từ chị Quỳnh :v  mỗi lần hỏi chị mở bài gì nghe đi cũng bảo bài nàii',
        ],
      },
      {
        name: 'Day 15',
        topic: 'A song from a movie soundtrack',
        watermelon: [
          'https://youtu.be/3fR9Uzt06WM',
          'https://youtu.be/oHT_XE3NONo',
          'https://youtu.be/YJcTHK4GdZA',
          'https://youtu.be/eeL8yAhqwUg',
          'https://youtu.be/52nfjRzIaj8',
          'https://youtu.be/9thM5gLs2tg',
          'https://youtu.be/l9PxOanFjxQ',
        ],
        grape: [
          'https://youtu.be/rQX3CzUyu58',
          'https://youtu.be/pcKR0LPwoYs',
          'https://youtu.be/uTvDTZc4Agw',
          'https://youtu.be/o63O5m5aNv8',
          'https://youtu.be/6kcNbcs-XYw',
          'https://youtu.be/YETtxwHNonY',
          'https://youtu.be/Y3kkGR1IvCI',
        ],
        comments: [
          'Goblin nè',
          'ơ màu của dưa Hấu...',
          'Nhận ra rùi hả =))',
          'Hôm nay nhiều bài quá',
          'Ôi đáng sợ...',
          'sao lại đáng sợ....em gửi nhiều bài cũng đáng sợ à',
          'không trong mv 2 bạn nhìn nhau ánh mắt sát thủ ý :((',
          'à haha, phim School 2015 đó em cũng thích, sắp có School 2020 rùi',
          'ôi Baymax cute dễ xương bồng bềnh',
          'Hihi',
        ],
      },
      {
        name: 'Day 16',
        topic: 'A song with no words',
        watermelon: [
          'https://youtu.be/O4N0Fsnsr4I',
          'https://youtu.be/2C4lFUpI_4U',
          'https://youtu.be/ZJR8tuO-mIU',
          'https://youtu.be/tLVDOTq5Vc0',
          'https://youtu.be/yF3lfqvOXPc',
        ],
        grape: [
          'https://youtu.be/hV5eeKUTzPU',
          'https://youtu.be/so6ExplQlaY',
          'https://youtu.be/5cINJwaAn4Y',
          'https://youtu.be/bg2ceESF-28',
          'https://youtu.be/Sijvkt5h4kc',
        ],
        comments: [
          'nghe huỳn bí wa~',
          'Nghe nói ai đó là Hoàng tử Mưa =))))',
          'Ỏ <3"',
          'it\'s up to you and it\'s up to me',
          'it\'s up to us',
        ],
      },
      {
        name: 'Day 17',
        topic: 'A song about being 17',
        watermelon: [
          'https://youtu.be/dB1w9Yiu3zo',
          'https://youtu.be/kILfB9lcMNE',
          'https://youtu.be/NoBAfjvhj7o',
        ],
        grape: [
          'https://youtu.be/ZwQuZp7sl4Q',
          'https://youtu.be/vOuBqt3PbwE',
        ],
        comments: [
          'Hôm nay chủ đề thú vị này =))',
          'Kiểu xem video mới thấy thú vị hơn nữa :>',
          'Chợt nhận ra mình đã sắp 21 cách xa tuổi 17 khá nhiều rồi =))',
        ],
      },
      {
        name: 'Day 18',
        topic: 'A song that reminds you of somebody',
        watermelon: [
          'https://youtu.be/I-KgyRp9_JQ',
        ],
        grape: [
          'https://youtu.be/C4IpVuSxo9k',
          'https://youtu.be/W9Ln7EqNCTw',
          'https://youtu.be/n9w472VEqvk',
        ],
        comments: [
          'Ơ Do Bongsoon nè =))',
          'ưm <3 xem lại đáng yêu quá cả đôi =)))',
        ],
      },
      {
        name: 'Day 19',
        topic: 'A song to drive to',
        watermelon: [
          'https://youtu.be/RgKAFK5djSk',
        ],
        grape: [
          'https://youtu.be/ghUh0NPHXy8',
        ],
        comments: [
          'bài ca tuổi trẻ nè~',
          'Nhìn như mario ấy nhỉ =))',
          'Hồi xưa anh thích Fast & Furious cực ý =)) Fan của đua xe =))',
          'ui hay quá lâu rùi mới nghe lại',
        ],
      },
      {
        name: 'Day 20',
        topic: 'A song with a number in the title',
        watermelon: [
          'https://youtu.be/IpniN1Wq68Y',
          'https://youtu.be/uWeqeQkjLto',
          'https://youtu.be/m6pW_q1PvH0',
        ],
        grape: [
          'https://youtu.be/GhWnG7YhcEE',
          'https://youtu.be/qxCCGcMC1Jk',
          'https://youtu.be/7IPNBW9fSvc',
        ],
        comments: [
          'Huhu anh k có ý quên mà :((',
          'I love you so much <3 <3',
          'bọ waaaaa ❤️',
        ],
      },
      {
        name: 'Day 21',
        topic: 'A song you listen to at 3 am',
        watermelon: [
          'https://youtu.be/1GKpu0WCkB0',
          'https://youtu.be/8TszjrFJOe0',
          'https://youtu.be/cquR2R7uWbM',
        ],
        grape: [
          'https://youtu.be/LspZCNNP6aE',
          'https://youtu.be/VeJFayj_aZ0',
          'https://youtu.be/_F4yGwCIok8',
        ],
        comments: [
          'ơ nhưng e đã bao h mở nhạc lúc 3h sáng đâu nhỉ :v  thế mở bài em nghĩ là sẽ nghe nha',
          'hình như những lần anh thức tới 3h thì toàn nhanh nhanh vào giường chứ k kịp mở nghe bài nào =))',
          '3am nằm nghe bài mưa ngâu... deep nhỉ',
          'deep để ngủ cho sâu đó =))',
          'bài đôi lời cũng nhẹ nhàng lắm nè',
          '<3 woah lãng mạn ghê',
          'hihi kết lãng mạn thật mặc dù lời bài hát thì k thế lắm...',
        ],
      },
      {
        name: 'Day 22',
        topic: 'A song with long title',
        watermelon: [
          'https://youtu.be/cWKTFuAFcOY',
          'https://youtu.be/iA9Ctaqo3Zc',
          'https://youtu.be/ITDsa_n_IGo',
        ],
        grape: [
          'https://youtu.be/wUtix5mnjIc',
          'https://youtu.be/YJfUn0BIfPc',
          'https://youtu.be/GQ4F9k4USfA',
        ],
        comments: [
          'Bài này anh cũng biết nè',
          'bài này em nghe từ hồi cấp 1 nè :))) vẫn nhớ vì tên dài quá',
          'Vậy hả hihi anh thì mới biết lun',
          'Anh cũng thích bài này lắm giọng Tóc Tiên nghe đặc biệt cực',
        ],
      },
      {
        name: 'Day 23',
        topic: 'A song with color in the title',
        watermelon: [
          'https://youtu.be/ZeSISQ9v4uc',
          'https://youtu.be/R-q5p6Db0P8',
          'https://youtu.be/F34e6LYro-4',
          'https://youtu.be/bzd4n1OEML4',
        ],
        grape: [
          'https://youtu.be/F4ELqraXx-U',
          'https://youtu.be/qcXR8cTzVCQ',
          'https://youtu.be/Zlot0i3Zykw',
        ],
        comments: [
          'Quay lại chủ đề đầu tiên nè =)) Lần này chơi hơi bị đẹp lun nha =))',
          'vừa mở khúc nhạc đầu của bài Violet thì Quỳnh Anh nhận ra luôn nhạc phim Violet =))))',
          'em biết Lana Del Rey qua bài Young and Beautiful vs Love hihi',
          'ui em cũng thích BLUE lắm nè',
          '💛 yellow heart lun nek',
          'à mà font chữ này giống trong lập trình kiểu gì ấy a nhỉ :v',
          'nó là font monospace đó nghĩa là các chữ cái đều có cùng một kích thước nên hay được dùng trong lập trình vì dễ đọc hơn =))',
        ],
      },
      {
        name: 'Day 24',
        topic: 'A song that you\'ve had stuck in your head',
        watermelon: [
          'https://youtu.be/d9IxdwEFk1c',
          'https://youtu.be/B6JVjuo1u5Q',
          'https://youtu.be/YlGsuRAUdG8',
        ],
        grape: [
          'https://youtu.be/yGpGsG8bvWk',
          'https://youtu.be/KAY9Lilqvz8',
        ],
        comments: [
          'VD có ai mà xin nhỗi là e nói luôn câu điệp khúc :))))',
          'ôi đáng sợ huhu vậy Hấu ngoan để hong phải xin lỗi :)))',
          'Có mún lớn hong hả Hấu :)',
          'Hong mún đâu mún nhỏ mãi mãi cùng Nho để được mãi mãi bên nhau <3',
        ],
      },
      {
        name: 'Day 25',
        topic: 'A song in a different language',
        watermelon: [
          'https://youtu.be/lY2yjAdbvdQ',
          'https://youtu.be/qc7vKaYgvjY',
        ],
        grape: [
          'https://youtu.be/Sz9b55vxmuk',
          'https://youtu.be/6EJjpNuk26E',
        ],
        comments: [
          'dao nay e thich giai dieu bai nay lam',
          'ôi anh vừa nghe xong cũng nghiền mất luôn rồi =))',
        ],
      },
      {
        name: 'Day 26',
        topic: 'A song that helps you fall asleep',
        watermelon: [
          'https://youtu.be/lbjZPFBD6JU',
          'https://youtu.be/KjgWWjkNbhU',
          'https://youtu.be/vv2mGGA4LVw',
        ],
        grape: [
          'https://youtu.be/2VMSdwRCKaU',
          'https://youtu.be/rf5pVEU_qoU',
          'https://youtu.be/F3nCVLlhXmc',
        ],
        comments: [
          'màu tím nè :v xinh dữ',
          '1 trong 10 bài e cho vào playlist Sleep :v',
          'ui nghe bùn ngủ thật =))) tên phim cũng gây cảm giác buồn ngủ nữa chứ',
        ],
      },
      {
        name: 'Day 27',
        topic: 'A song you describes how you feel in',
        watermelon: [
          'https://youtu.be/rZnE9HaCMCI',
        ],
        grape: [
          'https://youtu.be/VT1-sitWRtY',
        ],
        comments: [
          '~~~~~ <3',
          '~~~~~ <3 <3 <3',
        ],
      },
      {
        name: 'Day 28',
        topic: 'A song you used to hate but love now',
        watermelon: [
          'https://youtu.be/PVjiKRfKpPI',
        ],
        grape: [
          'https://youtu.be/cCOPLqDibfQ',
        ],
        comments: [
          'Bài này lúc đầu nghe ghê ghê mà nghe lại lại thấy hay hay :>',
          '- nghe còn nghĩ đến nghĩa trang =))',
        ],
      },
      {
        name: 'Day 29',
        topic: 'A song you have / want to download',
        watermelon: [
          'https://youtu.be/6u0DGIh3wLA',
          'https://youtu.be/WJTXDCh2YiA',
          'https://youtu.be/TgOu00Mf3kI',
          'https://youtu.be/HhZ1BdMtw_Q',
          'https://youtu.be/fzQ6gRAEoy0',
        ],
        grape: [
          'https://youtu.be/uelHwf8o7_U',
          'https://youtu.be/hPFZ1UAYoag',
          'https://youtu.be/vOSGa5MHBMI',
          'https://youtu.be/7D4Axk0vLag',
          'https://youtu.be/hdO9cc7WOyE',
          'https://youtu.be/9fdAt0ke9w8',
        ],
        comments: [
          'Mới biết Birdy nè anh thấy nghe cũng hay =))',
          'nghe thư giãn thật ấy',
          'nghe ảo diệu vạiiiiii',
          'Bad Liar hôm trước Nho bảo nè =))',
          'dung rui nhưng ở phiên bản khác hihi',
          'ơ bài này hay nhỉ hihi',
          'MV mới của IU nè',
          'bài này nghe tai nghe thích cực =)) nghe ảo quá =))',
          'eo em thích ost của phim Home lắmmm',
          'ơ anh chưa xem phim này nhìn hay thế =))',
          'xem đi nàoooo',
          'mai xem luôn nha =))',
          'làm anh nhớ tới bài này trong Meet the Robinsons',
          'Thống trị thế giới hả =))',
          'Cái này là phim ngắn. Kiểu lúc nãy xem MV của IU nghĩ tới bài này rồi xong rồi quên luôn. Xem lại lại nhớ =))',
          'ơ cái anh kia đi đâu thế ạ??',
          '<3',
        ],
      },
      {
        name: 'Day 30',
        topic: 'A song you want to share',
        watermelon: [
          'https://youtu.be/vGJTaP6anOU',
          'https://youtu.be/6Xlo2abddXU',
          'https://youtu.be/0put0_a--Ng',
        ],
        grape: [
          'https://youtu.be/AuDux09cl3A',
          'https://youtu.be/7i63YUbAGcM',
          'https://youtu.be/aCyj4w8GY1M',
        ],
        comments: [
          'Vậy là 30 ngày nữa đã trôi qua rồi 💓 Thêm một trang giấy được viết kín, cũng có nghĩa là một trang giấy trắng nữa đang chờ được viết lên trong câu chuyện của Hấu và Nho 🌟',
          'Đúng vậy và sẽ nhiều trang giấy nữa',
          'Ưm đúng vậy 💕',
          'Ỏ ngọt ngào quá!!!',
        ],
      },
    ],
  },
  {
    name: 'Month 3',
    days: [
      {
        name: 'Day 1',
        topic: 'A song that represents you',
        watermelon: [
          'https://youtu.be/uB_KiOjhF_k',
          'https://youtu.be/9JcUIgBhOgY',
          'https://youtu.be/cb5PalnCrhY',
        ],
        grape: [
          'https://youtu.be/emm0uGDGg2o',
          'https://youtu.be/Kj49gnIvdJM',
        ],
        comments: [
          'Hom nay round moi nha',
          'aww style mới xinh quá tiếp <3 designer Hấu hay s',
          'Đúng rùi đó copyright Hấu nha =)))',
          'Nho thích nhạc kiểu này hihi, ballad nhỉ',
        ],
      },
      {
        name: 'Day 2',
        topic: 'A song to play at a party',
        watermelon: [
          'https://youtu.be/1-xGerv5FOk',
          'https://youtu.be/vOXZkm9p_zY',
          'https://youtu.be/SmM0653YvXU',
        ],
        grape: [
          'https://youtu.be/prmmCg5bKxA',
          'https://youtu.be/PYI0E_Wr1gM',
          'https://youtu.be/JZELmUooBlY',
        ],
        comments: [
          'Đúng lúc hong =))',
          'ơ đúng :))',
          'ui giờ e mới xem mv của alone đó, hay thế :)) như xem phim hành động hnay í',
          '<3',
          'bài này nghe hay thế :))',
          'let me show what you missing, Paradise"" câu này em kết :v',
          'let\'s party~~~',
          'Hôm nay có party thật này =))',
          'hí hí hợp thé',
          'Hanoi in the rain today',
          'haha and then Hanoi in the sun',
        ],
      },
      {
        name: 'Day 3',
        topic: 'A song title with the name of a food',
        watermelon: [
          'https://youtu.be/ur692xBTisI',
        ],
        grape: [
          'https://youtu.be/BHb1VRju0RE',
        ],
        comments: [
          'Ngọt ngào như lớp kem tươi =))',
          'Là Nho đó =))',
          'ỏ thích chà đào à hnao đi uống chà đào nhé',
        ],
      },
      {
        name: 'Day 4',
        topic: 'An old song',
        watermelon: [
          'https://youtu.be/Ov0gL7wAL84',
        ],
        grape: [
          'https://youtu.be/qIt6KCwlFPw',
        ],
        comments: [
          'Bài này quen quen =)) Trước Nho gửi rồi thì phải',
          'gửi òi thì hong đc gửi nữa àaa',
          'được chứ quá được lun =))',
        ],
      },
      {
        name: 'Day 5',
        topic: 'A song that makes you feel nostalgic',
        watermelon: [
          'https://youtu.be/p8A3ULXgV3E',
        ],
        grape: [
          'https://youtu.be/4y-gaQFv6ro',
        ],
        comments: [
          'em thích bài này cực, mà ko tìm lại được bản nhảy hồi cấp bar  =))))',
          'lúc nào tìm thấy cho anh xem với nha =))',
          'nhảy lại luôn cho xem còn được hi',
          'thế hả lúc nào bật nhạc rồi em nhảy nhé =)))',
          'ơ nhưng bài này phải nhảy nhóm mới hăng nên là anh sẽ phải nhảy cùng em nhaaaaa',
          'ưm ôi hồi xưa anh cũng tham gia tập nhảy lúc mới vào c3 nhé =)) tập theo mấy anh kpop luôn =))) mỗi tội k có năng khiếu :))',
          'hihi yêu thế =)))',
          'ơ bài kia thì là nhớ quê hương hả Hấu',
          'hong đơn giản là hoài niệm thôi cảm thấy đã nghe bài này lâu lắm rồi khs anh cảm giác anh biết bài này từ trước khi nó ra đời :))',
          '=)))) woah sao lại thế mà lúc đó có hoài niệm gì sao ạ ....',
          'anh cũng k biết là hoài niệm về gì nữa chỉ là nghe cảm giác đó. hình như là do đám cưới của cô giáo chủ nhiệm anh c3 có bật bài này sao ý :)) nhanh ghê đã 6 năm rồi',
          'xem hạnh phúc thật sự ấy :)',
          '<3 <3 <3"',
        ],
      },
      {
        name: 'Day 6',
        topic: 'A song about love',
        watermelon: [
          'https://youtu.be/8HLVzsjmxvM',
          'https://youtu.be/P3dElGxuLgY',
          'https://youtu.be/QZff_eFf6eE',
          'https://youtu.be/__kGJZ-kPno',
          'https://youtu.be/ShZ978fBl6Y',
        ],
        grape: [
          'https://youtu.be/0XFudmaObLI',
          'https://youtu.be/tB1PoyZtrzY',
          'https://youtu.be/BnfaIDwb-y0',
          'https://youtu.be/TKlXc3iywoM',
          'https://youtu.be/hf96Fl3XQsg',
        ],
        comments: [
          'lớp gì lớp diu Nho yêu anh',
          'anh cũng yêu Nho',
          'ơ jisoo hát lời Nhật luôn hả anh',
          'ưm lời Nhật đó',
          'anh đó anh đóooo',
          'Nho đó Nho đóooo',
          'BTS nhạc cũng hay óoo',
          'cũng có bài hay hihi',
          'anh yêu em rất rất rất nhiều',
          '❤️❤️❤️',
          'bài hát cừi ghê, nhạc với lời cưng xỉu',
          'bài này Hấu hong hiểu lời nhưng mà hình như cũng về tình yêu =))',
          'ơ lời bài này ạ? lời bài của Hấu đó',
          'bài xứng đôi đáng yêu quá =))',
          'em nghe được câu ka wa yoi deso gì ấy :)) mà kawai là đáng yêu nhỉ',
          'đúng ròi =))) đáng yêu như Nho ấy =))',
          'a anh biết bài này lâu lắm rồi thích bài này cực =))',
          '=((( hôm nào Hấu hát cho Nho nghe bài này nha',
          'giọng ca vàng của Hấu lúc nào cũng sẵn sàng hihi',
          'hí hí vinh dự cá',
          'bài này ngọt ngào quá',
          'bài của anh cũng làm em ngã ra đấy rồi :3',
          '❤❤❤',
        ],
      },
      {
        name: 'Day 7',
        topic: 'A song from the year you were born',
        watermelon: [
          'https://youtu.be/t0bPrt69rag',
          'https://youtu.be/jjnmICxvoVY',
        ],
        grape: [
          'https://youtu.be/ALmvzklaWWM',
          'https://youtu.be/vzerbXFwGCE',
        ],
        comments: [
          'em tìm thấy bài này hình như là nhạc phim từ năm 2000, mà nghe vẫn hợp thời chứ lị :) nghe quá là hay luôn ấy chứ =))',
          '"Leave me breathless!',
          '<3 i knew i loved you before i met you',
        ],
      },
      {
        name: 'Day 8',
        topic: 'A song title with the name of a person',
        watermelon: [
          'https://youtu.be/281y76Qw1a8',
          'https://youtu.be/L7aSiIk6WKs',
          'https://youtu.be/QdUdQzyAaVA',
        ],
        grape: [
          'https://youtu.be/NutHKRKBgR0',
          'https://youtu.be/IdneKLhsWOQ',
          'https://youtu.be/S0c14NdWkHA',
        ],
        comments: [
          'Lần trước đến chủ đề này hình như dỗi Nho thì phải :)) Nhưng lần này chơi đẹp :))',
          'gớm, xong Hấu gửi mấy bài 10 độ C, yêu đi đừng sợ ấy ạ :3',
          'ôi bài này hay quá buồn buồn nữa :(((',
          'Người yêu dấu hỡi~~~~',
          'có một người tên là Nho nè :3',
          'ơ Lương MInh Trang lúc trước em thích nghe bài Tan lắm',
          'em yêu anh rất nhiều, nhiều nhiều lắm❤❤❤',
          'anh cũng yêu em rất rất rất nhiều người yêu bé bỏng của anh ❤❤❤',
        ],
      },
      {
        name: 'Day 9',
        topic: 'A song that cheers you up',
        watermelon: [
          'https://youtu.be/VPRjCeoBqrI',
          'https://youtu.be/F90Cw4l-8NY',
        ],
        grape: [
          'https://youtu.be/XokWF3jHSgM',
          'https://youtu.be/kbPDfaLLYV4',
        ],
        comments: [
          'đưa tay đây nèo',
          'mãi bên nhau nhé <3',
          'vui cá á á',
          'bài buồn mà remix cái vui luôn :))',
          'sức mạnh của remix =))',
          'ôi bài pompeii bao nhiêu lâu cũng tìm được rồi =)) bao nhiêu lần anh muốn gửi mà k nhớ được tên nó',
          'ôi nhìn mắt sợ quá =((',
          'vậy bài này anh thích nghe từ ngày xưa hả',
          'ưm cũng hơi hơi thích =))',
        ],
      },
      {
        name: 'Day 10',
        topic: 'A song you never get tired of',
        watermelon: [
          'https://youtu.be/zNm6kqasC7k',
          'https://youtu.be/yazn0uMP-Fk',
        ],
        grape: [
          'https://youtu.be/YjiOiJW9_uE',
          'https://youtu.be/nZjTgdo4C8g',
        ],
        comments: [
          'nghe bánh cuốn ghê',
          'hay quá đi thôi !!',
          'em nhớ cảm giác mà nhớ lắm....',
          '❤️',
          'bài này em biết từ bản mashup trong pitch perfect ne',
          'ôi mashup bn bài luôn =))',
          'hay tóa phim này về âm nhạc hả anh chưa xem',
          'ưm xem phim tí lại có bài hát',
        ],
      },
      {
        name: 'Day 11',
        topic: 'A song you used to like but now hate',
        watermelon: [
          'https://youtu.be/oyEuk8j8imI',
        ],
        grape: [
          'https://youtu.be/7ZVSflsk7UM',
        ],
        comments: [
          'bài này hong hẳn là ghét nhưng ko thích mà sau nghe bị nghiện  á',
          'tức là bh nghiện hả =))',
          'ơ em nhầm :))) tưởng là giống đợt trc là trước ghét sau thích :3',
          'ôi hôm nay update sớm thế tối qua anh định sáng nay dậy add =))',
          'bài love yourself trước anh thích nhạc mà giờ nhận ra lời hong có hay',
        ],
      },
      {
        name: 'Day 12',
        topic: 'A song you want to play at your wedding',
        watermelon: [
          'https://youtu.be/0DZcbLKhE8w',
        ],
        grape: [
          'https://youtu.be/bs7u95QlCxs',
          'https://youtu.be/tv0ttp0bS8A',
        ],
        comments: [
          'thích Mãi Mãi Bên nhau từ ngày xưa hihi',
          'Nho nhớ lần trước bàn là phải mở đủ thể loại nhạc từ us-uk tới kpop jpop rồi tq luôn nhỉ =))',
          'hôm trước em còn bo nút thêm bài WIp Wup Thái lun nha :3',
          'Haha',
        ],
      },
      {
        name: 'Day 13',
        topic: 'A song you discovered this year',
        watermelon: [
          'https://youtu.be/syFZfO_wfMQ',
          'https://youtu.be/m3DZsBw5bnE',
        ],
        grape: [
          'https://youtu.be/FoflLrb92ZQ',
          'https://youtu.be/SvAQAeLa-jY',
        ],
        comments: [
          'Em mới bít bản cover này nghe bắt tai nắmm',
          'Iuuuu',
          'xem Night Changes kiểu khối girls ghép vid cùng nhỉ :v',
          'hình như thế =))',
          'điệp khúc hayyy ghe á',
          'ocean k run dry được vì Hấu là Thủy Lực hihi',
          'big girls don\'t cry ôi tiêu đề nghe vừa đáng yêu vừa buồn buồn',
          'ưmm điệp khúc nghe da diết luôn',
          'hihi, ơ mà bài How can I love the heartbreak, you`re the one I love nghe như OST phim nào ấy ạ :v',
          'anh nghe cũng thấy quen quen mà hình như k phải OST 🧐',
        ],
      },
      {
        name: 'Day 14',
        topic: 'The last song you\'ve listened to',
        watermelon: [
          'https://youtu.be/0hv2SF0S8rQ',
        ],
        grape: [
          'https://youtu.be/U6DEAuk1hs8',
        ],
        comments: [
          'Nho mới mở lại, còn nhớ LOYT em hay nghe lúc cuối đợt 2018 hihi',
          'Còn Hấu mới tìm thấy Song for you hôm nay =))',
          'yêu chế ạaaaa',
          'Giờ anh mới biết Thanh Duy đó anh này cũng hát hay quá =))',
          'ư hay mà =))',
        ],
      },
      {
        name: 'Day 15',
        topic: 'A song with a title you like',
        watermelon: [
          'https://youtu.be/AiD1a2fFFLw',
        ],
        grape: [
          'https://youtu.be/c9tW8bWq50c',
        ],
        comments: [
          'Không sao mà anh cũng đây rồi',
          '<3  <3',
        ],
      },
      {
        name: 'Day 16',
        topic: 'A song for running away from home',
        watermelon: [
          'https://youtu.be/UwxatzcYf9Q',
        ],
        grape: [
          'https://youtu.be/MDt1Ed_Qwlo',
        ],
        comments: [
          'Save & Seve',
          'Anh & Em',
          'hihi phát hiện thậc là siu 💓',
        ],
      },
      {
        name: 'Day 17',
        topic: 'A song title with an acronym',
        watermelon: [
          'https://youtu.be/vdj6R-Etk3k',
        ],
        grape: [
          'https://youtu.be/t5Sd5c4o9UM',
        ],
        comments: [
          'ET ảo diệu tóa =))',
          'úi thích quá',
          'XOXO anh nhé"',
        ],
      },
      {
        name: 'Day 18',
        topic: 'A song title with 1 word',
        watermelon: [
          'https://youtu.be/LBcR4OkS_tQ',
        ],
        grape: [
          'https://youtu.be/BVmgiVNV0HI',
        ],
        comments: [
          'Hấu nghe bài này để thư giãn nè',
          'hong cần hiểu, chỉ nghe thôi',
          'Bài này nhẹ nhàng cảm xúc quá, nghe xong cảm giác nhẹ nhõm thật',
        ],
      },
      {
        name: 'Day 19',
        topic: 'A song that sounds better with headphones',
        watermelon: [
          'https://youtu.be/iuJDhFRDx9M',
        ],
        grape: [
          'https://youtu.be/qrO4YZeyl0I',
        ],
        comments: [
          'Mai em thi bình tĩnh tự tin nhé!',
          'ôi cho ngta xem hẳn bài đua xe luôn :)))) may chưa áp dụng vào bài thi nhớ',
        ],
      },
      {
        name: 'Day 20',
        topic: 'A song for stargazing',
        watermelon: [
          'https://youtu.be/Ar48yzjn1PE',
        ],
        grape: [
          'https://youtu.be/hEdvvTF5js4',
        ],
        comments: [
          'Huhu kết buồn quá nhưng vì đam mê mà :((',
          'Hồi xưa Hấu cũng thích du lịch vũ trụ lắm =))',
        ],
      },
      {
        name: 'Day 21',
        topic: 'A song for the beach',
        watermelon: [
          'https://youtu.be/9thM5gLs2tg',
        ],
        grape: [
          'https://youtu.be/LPaICVMoN0U',
        ],
        comments: [
          'chào anhhh =)) ơ ơ',
          'Hihi ủa tưởng hôm qua là bài về biển =))',
          'em tưởng là mở khi đang ở biển =))',
          'À ha =))',
        ],
      },
      {
        name: 'Day 22',
        topic: 'A song about friendships',
        watermelon: [
          'https://youtu.be/NIshSFL67hU',
        ],
        grape: [
          'https://youtu.be/AZLfAzVy_PM',
        ],
        comments: [
          'Bài này Nho trước nói thích đúng hong =))',
          'hhihi em đợt trước nói đến bài Proud of you đó, mà bài này cũng hay ơi hay :))',
          'Ưm ưm :))',
          'Daisuki da yo',
          'Yêu lắm lắm cơ <3',
          'Saranghage',
          'Chù a hề',
          '=)))',
        ],
      },
      {
        name: 'Day 23',
        topic: 'A song for breakups',
        watermelon: [
          'https://youtu.be/iwGuiSnr2Qc',
          'https://youtu.be/wDp51iSbjwA',
        ],
        grape: [
          'https://youtu.be/VcQ6KGN4RAg',
          'https://youtu.be/hLQl3WQQoQ0',
        ],
        comments: [
          'chủ đề nhạy cảm quá nhỉ =)))',
          'bài búp bê tóc đỏ nè, ngày trc Nho thích mê hình tượng này của chị Đông Nhi luôn :))',
          'Haha còn bài này lần đầu mới biết đó chị Đông Nhi tóc đỏ =))',
          'ủa mà hum nay màu đen là có ý gì hong zợ :3',
          'chủ đề nhạy cảm nên màu đen đó :))',
        ],
      },
      {
        name: 'Day 24',
        topic: 'A song by one of your favorite artists',
        watermelon: [
          'https://youtu.be/4OrCA1OInoo',
        ],
        grape: [
          'https://youtu.be/lCSHxfnrq2k',
        ],
        comments: [
          'Taeyeon nha =))',
          'hay qua iiii eo oi e thich bai nay cuc',
        ],
      },
      {
        name: 'Day 25',
        topic: 'A song you sent to someone',
        watermelon: [
          'https://youtu.be/tk2jlrGgoks',
        ],
        grape: [
          'https://youtu.be/ywWVE59gwvs',
        ],
        comments: [
          'chiu chiu nha',
          'aww anh cũng vừa nghe bài này sáng nay =))',
          'bài WHITEEEEN & GreeeeN nghe vui thế hihi',
          'vui để Nho nghe cũng vui theo mò =))',
        ],
      },
      {
        name: 'Day 26',
        topic: 'A cover of a song',
        watermelon: [
          'https://youtu.be/9qR6YkBcZsk',
        ],
        grape: [
          'https://youtu.be/jG-ZIQCQvEI',
        ],
        comments: [
          'SeoHyun của SNSD nè cover 3 bài lun =))',
          'bài đầu của IU lun',
          'i can show you the whole new world my one and only princess',
        ],
      },
      {
        name: 'Day 27',
        topic: 'A song about being angry',
        watermelon: [
          'https://youtu.be/8nBFqZppIF0',
        ],
        grape: [
          'https://youtu.be/Mgfe5tIwOj0',
        ],
        comments: [
          'bài này nghe đến lần 2 thì em nghiện :))',
          'hay tóa anh cũng nghiện mất rồi =)))',
        ],
      },
      {
        name: 'Day 28',
        topic: 'A song to play while running',
        watermelon: [
          'https://youtu.be/mEuEP8A2Ob4',
        ],
        grape: [
          'https://youtu.be/ALzPt-7pEOc',
        ],
        comments: [
          'em có thể hiểu lời bài hát của anh là điều anh muốn nói phải không?',
          'không đó không phải là những điều anh muốn nói nhưng khi nghe em nói đi chơi tới hơn 10h 11h rồi không trả lời tin nhắn của anh nữa thì tự nhiên anh sợ những điều đó sẽ xảy ra',
        ],
      },
      {
        name: 'Day 29',
        topic: 'A song to play during a date',
        watermelon: [
          'https://youtu.be/lGc--D3mxo0',
        ],
        grape: [
          'https://youtu.be/hdl6OdypK8k',
        ],
        comments: [
          'Cam on anh vi da luon ben em nhe',
          'Cảm ơn em vì cũng đã luôn bên anh',
          'XOXO my super Hấu',
        ],
      },
      {
        name: 'Day 30',
        topic: 'A song you want to share',
        watermelon: [
          'https://youtu.be/_VGm6brq1aI',
        ],
        grape: [
          'https://youtu.be/yr_ntsmrHV4',
        ],
        comments: [
          'Được hong',
          'Còn hỏi nữa hả :3 Vậy thương được không?',
          'Lại một bài về yêu đi nè :3',
          'Hi vừa yêu vừa thương nhiều nhắm',
        ],
      },
    ],
  },
];
