import React from 'react';
import ReactPlayer from 'react-player/youtube';
import classNames from 'classnames';
import { FaBackward, FaForward, FaRandom } from 'react-icons/fa';
import produce from 'immer';

import { data } from '../songcalendar/data';
import { YoutubeVideo, youtubeVideos } from '../songcalendar/youtubeVideos';

export type PlaylistPlayerWithSelectorProps = {
  round: number;
  day: number;
};

export const PlaylistPlayerWithSelector: React.FC<PlaylistPlayerWithSelectorProps> = ({ round, day }) => {
  const watermelons = data[round].days[day].watermelon.map(videoLink => videoLink.substr('https://youtu.be/'.length))
    .filter(videoId => youtubeVideos.get(videoId) !== undefined);
  const grapes = data[round].days[day].grape.map(videoLink => videoLink.substr('https://youtu.be/'.length))
    .filter(videoId => youtubeVideos.get(videoId) !== undefined);
  const [videoIds, setVideoIds] = React.useState<string[]>([...grapes, ...watermelons]);

  return (
    <div>
      <div className="flex justify-center mb-4">
        <button
          className="px-2 py-1 bg-purple-400 text-white rounded-md shadow-md text-md hover:bg-purple-300 mr-2"
          onClick={() => setVideoIds(grapes)}
        >
          Bài của Nho Nhỏ
        </button>
        <button
          className="px-2 py-1 bg-red-400 text-white rounded-md shadow-md text-md hover:bg-red-300 mr-2"
          onClick={() => setVideoIds(watermelons)}
        >
          Bài của Hấu Béo
        </button>
        <button
          className="px-2 py-1 bg-yellow-400 text-white rounded-md shadow-md text-md hover:bg-yellow-300"
          onClick={() => setVideoIds([...grapes, ...watermelons])}
        >
          Bài của 2 chúng mình :v
        </button>
      </div>
      <div>
        <PlaylistPlayer videoIds={videoIds} width={700} playing={Math.random()} round={round}/>
      </div>
    </div>
  );
};

export type PlaylistPlayerProps = {
  videoIds: string[];
  width: number;
  height?: number;
  playing: number;
  round: number;
};

export const PlaylistPlayer: React.FC<PlaylistPlayerProps> = ({ videoIds, width, height, playing, round }) => {
  const [selectedVideoPos, setSelectedVideoPos] = React.useState<number>();
  const [shuffle, setShuffle] = React.useState(false);
  const [nextSongs, setNextSongs] = React.useState<number[]>([]);
  const [actualPlaying, setActualPlaying] = React.useState<boolean>(false);

  React.useEffect(() => {
    setSelectedVideoPos(0);
  }, [videoIds]);

  React.useEffect(() => {
    setActualPlaying(false);
  }, [playing]);

  if (!videoIds.length) {
    return null;
  }

  const setPrevSong = () => {
    setSelectedVideoPos(selectedVideo => {
      if (selectedVideo === undefined) {
        return undefined;
      }
      return selectedVideo === 0 ? videoIds.length - 1 : selectedVideo - 1;
    });
  };

  const setNextSong = () => {
    setSelectedVideoPos(selectedVideo => {
      if (selectedVideo === undefined) {
        return undefined;
      }
      return selectedVideo === videoIds.length - 1 ? 0 : selectedVideo + 1;
    });
  };

  const toggleShuffle = () => {
    if (selectedVideoPos === undefined) {
      return;
    }
    if (shuffle) {
      setShuffle(false);
      setNextSongs([]);
      return;
    }
    const songs = Array.from(videoIds.keys()).filter(i => i !== selectedVideoPos).sort(() => 0.5 - Math.random());
    songs.push(selectedVideoPos);
    setNextSongs(songs);
    setShuffle(true);
  };

  const selectNextSong = () => {
    setActualPlaying(true);
    if (!shuffle) {
      setNextSong();
      return;
    }
    setSelectedVideoPos(nextSongs[0]);
    setNextSongs(produce(nextSongs, draft => {
      const first = draft.shift();
      if (first !== undefined) {
        draft.push(first);
      }
    }));
  };

  const videoCards: React.ReactElement[] = [];
  for (let i = 0; i < videoIds.length; i++) {
    const videoId = videoIds[i];
    const video = youtubeVideos.get(videoId);
    if (!video) {
      continue;
    }
    videoCards.push(
      <VideoCard
        video={video}
        key={`video-card-${round}-${i}-${videoId}`}
        selected={i === selectedVideoPos}
        onClick={() => {
          setSelectedVideoPos(i);
          setActualPlaying(true);
        }}
      />);
  }

  const selectedVideo = selectedVideoPos !== undefined && youtubeVideos.get(videoIds[selectedVideoPos]);
  return (
    <div className="shadow rounded bg-white" style={{ width: `${width}px` }}>
      <div>
        {selectedVideo ? (
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${selectedVideo.id}`}
            controls={true}
            width={width}
            height={393}
            playing={actualPlaying}
            onEnded={selectNextSong}
          />
        ) : (
          <div className="bg-white" style={{ height: '393px' }}/>
        )}
      </div>
      <div className="font-semibold text-sm text-gray-700 px-3 py-3 truncate border-b bg-gray-50">
        Playing: {selectedVideo ? selectedVideo.title : 'Không có bài hát được chọn'}
      </div>
      <div className="px-3 py-1 bg-gray-50 border-b">
        <button
          className="p-2 hover:bg-gray-200 rounded mr-2"
          onClick={setPrevSong}
        >
          <FaBackward size={20} className="text-gray-700"/>
        </button>
        <button
          className="p-2 hover:bg-gray-200 rounded mr-2"
          onClick={selectNextSong}
        >
          <FaForward size={20} className="text-gray-700"/>
        </button>
        <button
          className={classNames('p-2 rounded', shuffle ? 'bg-gray-300' : 'hover:bg-gray-200')}
          onClick={toggleShuffle}
        >
          <FaRandom size={20} className="text-gray-700"/>
        </button>
      </div>
      <div className="overflow-y-scroll" style={{ maxHeight: `${height || 600}px` }}>
        {videoCards}
      </div>
    </div>
  );
};

type VideoCardProps = {
  video: YoutubeVideo,
  selected: boolean,
  onClick: () => void,
};

const VideoCard: React.FC<VideoCardProps> = ({ video, selected, onClick }) => {
  const cardRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (selected && cardRef && cardRef.current) {
      cardRef.current.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
  }, [selected]);
  return (
    <div
      className={classNames(
        'flex border-b border-l border-r border-gray-200 px-3 py-2 bg-white cursor-pointer hover:bg-gray-100',
        selected ? 'bg-gray-200 text-gray-900 hover:bg-gray-200 font-semibold' : '',
      )}
      onClick={onClick}
      ref={cardRef}
    >
      <div
        style={{
          backgroundImage: `url(${video.thumbnail})`,
          backgroundPosition: 'center',
          objectFit: 'cover',
          height: '56px',
          width: '100px',
        }}
      />
      <div style={{ width: 'calc(100% - 100px)' }}
        className="text-gray-700 text-sm ml-2 break-words">{video.title}</div>
    </div>
  );
};
